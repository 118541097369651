
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class ConstrainForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Language, label: 'constrain.form.name', rules: 'required' },
      { name: 'isActive', type: InputType.Checkbox, label: 'constrain.form.active', default: true },
    ];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
